import React from 'react'
import theme from '../constants/theme'
import { ThemeProvider } from 'emotion-theming'
import HeroSmall from '../components/HeroSmall'
import H2 from '../components/H2'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Link from '../components/Link'
import { FormattedMessage } from 'react-intl'

const GDPRTable = styled.table`
  margin-bottom: ${theme.margin.small};

  &,
  & td {
    border: 1px solid black;
    padding: 0 6px;
    text-align: right;
  }
`

const LinkWithIcon = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin-bottom: 18px;

  *:first-child {
    max-width: 112px;
    margin-right: 12px;
  }

  span {
    max-width: 200px;
  }
`

const NotFoundPage = ({ data }) => {
  // console.log('dataaa', data)
  return (
    <ThemeProvider theme={theme.palette.white}>
      <div>
        <HeroSmall>
          <div className="container text-center">
            <H2><FormattedMessage id = "privacy.header1" /></H2>
          </div>
        </HeroSmall>
        <div className="container" style={{ marginBottom: theme.margin.mid }}>
          <div>
            <p>
               
            </p>
            <p>
             <FormattedMessage id = "privacy.p1" />
            </p>
            <p>
             <FormattedMessage id = "privacy.p2" />
            </p>
            <p>
              <FormattedMessage id = "privacy.p3" />
            </p>

            <h2><FormattedMessage id = "privacy.header2" /></h2>
            <h3><FormattedMessage id = "privacy.header3" /></h3>
            <p>
            <FormattedMessage id = "privacy.p4" />
            </p>

            <h3><FormattedMessage id = "privacy.header4" /></h3>
            <p>
             <FormattedMessage id = "privacy.p5" /> 
            </p>

            <h3><FormattedMessage id = "privacy.header5" /></h3>
            <p>
             <FormattedMessage id = "privacy.p6" />
            </p>
            <p>
             <FormattedMessage id = "privacy.p7" /> 
            </p>

            <h3><FormattedMessage id = "privacy.header6" /></h3>
            <p>
             <FormattedMessage id = "privacy.p8" /> 
            </p>

            <h3><FormattedMessage id = "privacy.header7" /></h3>
            <p>
             <FormattedMessage id = "privacy.p9" /> 
            </p>
            <p>
             <FormattedMessage id = "privacy.p10" /> 
            </p>

            <h3><FormattedMessage id = "privacy.header8" /></h3>
            <p>
             <FormattedMessage id = "privacy.p11" /> 
            </p>

            <h3><FormattedMessage id = "privacy.header9" /></h3>
            <p>
             <FormattedMessage id = "privacy.p12" />  
            </p>
            <p>
             <FormattedMessage id = "privacy.p13" />
            </p>

            <h2><FormattedMessage id = "privacy.header10" /></h2>

            <p>
             <FormattedMessage id = "privacy.p14" />  
            </p>
            <ul>
              <li><FormattedMessage id = "privacy.list1" /></li>
              <li>
               <FormattedMessage id = "privacy.list2" />
              </li>
              <li><FormattedMessage id = "privacy.list3" /></li>
            </ul>

            <h2><FormattedMessage id = "privacy.header11" /></h2>
            <p>
             <FormattedMessage id = "privacy.p15" /> 
            </p>
            <GDPRTable className="gdpr-table">
              <tbody>
                <tr>
                  <td>53621</td>
                  <td>1234</td>
                  <td>34355</td>
                  <td>227</td>
                  <td>11/12/2017 14:02</td>
                  <td>11/12/2017 14:02</td>
                  <td>27655</td>
                  <td>27655</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.25</td>
                </tr>
                <tr>
                  <td>24536</td>
                  <td>1235</td>
                  <td>35226</td>
                  <td>228</td>
                  <td>11/11/2017 17:03</td>
                  <td>11/11/2017 17:04</td>
                  <td>80987</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>80987</td>
                  <td>0.65</td>
                </tr>
                <tr>
                  <td>24356</td>
                  <td>1455</td>
                  <td>26644</td>
                  <td>229</td>
                  <td>12/27/2017 21:04</td>
                  <td>12/27/2017 21:04</td>
                  <td>25657</td>
                  <td>0</td>
                  <td>0</td>
                  <td>25657</td>
                  <td>0</td>
                  <td>0.95</td>
                </tr>
                <tr>
                  <td>97812</td>
                  <td>6444</td>
                  <td>7555</td>
                  <td>230</td>
                  <td>9/25/2018 13:04</td>
                  <td>9/25/2018 13:04</td>
                  <td>31440</td>
                  <td>0</td>
                  <td>31440</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.33</td>
                </tr>
                <tr>
                  <td>32566</td>
                  <td>5999</td>
                  <td>53211</td>
                  <td>607</td>
                  <td>9/15/2018 23:58</td>
                  <td>9/15/2018 23:58</td>
                  <td>11322</td>
                  <td>11322</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.15</td>
                </tr>
              </tbody>
            </GDPRTable>
            <p>
             <FormattedMessage id = "privacy.p16" />
            </p>

            <p>
             <FormattedMessage id = "privacy.p17" />  
            </p>
            <p>
              <FormattedMessage id = "privacy.p18" /> {' '}
              <Link to="https://www.vretta.com/trust-center">
                Vretta Trust Centre
              </Link>{''}.
            </p>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default NotFoundPage

export const dataIconFrag = graphql`
  fragment dataIcon on File {
    childImageSharp {
      fixed(width: 112, quality: 90) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`;
